<template>
  <b-overlay
      :show="tags === null"
      rounded="sm"
  >
    <div v-if="tags !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                  variant="primary"
                  @click="addNewCategory = !addNewCategory"
              >
                <span class="text-nowrap">+ ساخت برچسب جدید</span>
              </b-button>
            </b-col>
            <!--   new Category name  -->
            <transition name="fade">
              <b-col
                  v-if="addNewCategory"
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
              >
                <b-col md="8">
                  <div class="form-label-group">
                    <b-form-input
                        id="name"
                        placeholder="نام برچسب"
                        v-model="defaultCreateData.value"
                    />
                  </div>
                </b-col>
                <b-col md="2" class="mb-1">
                  <b-button
                      @click="createNewTag"
                      variant="success"
                  >
                    <span class="text-nowrap">ساخت</span>
                  </b-button>
                </b-col>
              </b-col>
            </transition>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="tags"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: delete -->

          <template #cell(delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.tagId)"
            >
              <feather-icon size="20" icon="TrashIcon" class="text-danger" />
            </div>

          </template>

          <template #cell(edit)="data">

            <router-link :to="{ name: 'pages-blog-tags-edit', params: { id: data.item.blogCategoryId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon size="20" icon="EditIcon" />
            </router-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>

            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteTag(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryGetAllRequest, TagCreateRequest, TagDeleteRequest, TagGetAllRequest} from "@/libs/Api/Blog";
import {BlogCategoryCreateRequest} from "@/libs/Api/Blog";
import {BlogCategoryDeleteRequest} from "@/libs/Api/Blog";

export default {
  name: "tagsList",
  title:"برچسب های بلاگ",
  data(){
    return{
      tags:null,
      totalCount:null,
      addNewCategory:false,
      deleteItem:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      myTableColumns : [
        { key: 'tagId',label:'شناسه'},
        { key: 'value',label:'نام'},
        { key: 'delete',label:'حذف'},
        // { key: 'edit',label:'ویرایش'},
      ],
      defaultCreateData:{
        isDeleted:false,
        tagId:0,
        value:'',
        createDate:new Date(Date.now())
      },
    }
  },
  async created(){
    await this.getBlogTags(this.currentPage,this.perPage)
  },
  components:{

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
  methods:{
    async getBlogTags(pageNumber,count){
      let _this = this;

      let data={
        pageNumber:pageNumber,
        count:count
      }

      let tagGetAllRequest = new TagGetAllRequest(_this);
      tagGetAllRequest.setParams(data);
      await tagGetAllRequest.fetch(function (content){
        _this.tags = content.tags;
        _this.totalCount = content.tagsCount;
      } , function (content){

      });
    },
    async createNewTag(){
      let _this = this;

      let tagCreateRequest = new TagCreateRequest(_this);
      tagCreateRequest.setParams(_this.defaultCreateData);
      await tagCreateRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: ` برچسب اضافه شد.`,
          },
        })
        _this.getBlogTags(_this.currentPage,_this.perPage);
      },function (error){
        window.location.reload();
        console.log(error)
      })
    },
    async deleteTag(param){
      let _this = this;
      let data ={
        id:param
      }

      let tagDeleteRequest = new TagDeleteRequest(_this);
      tagDeleteRequest.setParams(data);
      await tagDeleteRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب حذف شد.`,
          },
        })
        _this.getBlogTags(_this.currentPage,_this.perPage);
      },function (error){
        console.log(error)
      })
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getBlogTags(this.currentPage,nv);
    },
    currentPage:function (nv,ov) {
      this.getBlogTags(nv,this.perPage);
    },
  },
}
</script>

<style scoped>

</style>
